import { Link } from "react-router-dom"
import Whatsapp from '../img/whatsapp.png'
import AppleIcon from '../img/app-store.png'
import Googlepay from '../img/google-play.png'
const Footer = () => {
    return (
        <>
            <footer className="position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Quick Links</h5>
                            <ul className="footer-links">
                                <li>
                                    <Link to={'/'}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/about'}>
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/my-bookings'}>
                                        My Bookings
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/terms'}>
                                        Terms & Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/privacy'}>
                                        Privacy Policy
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to={'/'}>
                                        Sitmap
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to={'/faq'}>
                                        FAQ's
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Download</h5>
                            <div className="">
                                <Link to={'https://play.google.com/store/apps/details?id=com.themovers.themovers_customer'}>
                                    <img src={Googlepay} className="footer-logos" />
                                </Link>
                            </div>
                            <div className="mb-4">
                                <Link to={'https://apps.apple.com/my/app/themovers-moving-services/id6470928815'}>
                                    <img src={AppleIcon} className="footer-logos" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Social Links</h5>
                            <div className="d-flex">
                                <Link to={'https://www.facebook.com/themoversonIine'} className="social-bg-style">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </Link>
                                {/* <Link to={'/'} className="social-bg-style">
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </Link> */}
                                <Link to={'https://www.instagram.com/themoversonline?igsh=MWw1dmJoYXZoeWNxMw%3D%3D'} className="social-bg-style">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </Link>
                                {/* <Link to={'/'} className="social-bg-style">
                                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Northern- HQ</h5>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">The Movers Online (M) Sdn Bhd (1383901-A)
                                        Northern- HQ Suite B3.1C1, Ground Floor, KHTP Business Centre, Kulim Hi-Tech, P.O Box, 09090 Kulim,
                                        Kedah Darul Aman, Malaysia.</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">booking.themovers.my@gmail.com</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-2661021</p>
                                </div>
                            </div> <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-3244261 (Hotline)</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <a href="https://wa.me/+60123244261" target="_blank" className="whatsup-icon-bottom-style">
                        <i class="fa fa-whatsapp whatsup-size" aria-hidden="true"></i>
                    </a> */}
                    <div className="container address-container">

                    </div>

                </div><div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Central</h5>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">The Movers Online (M) Sdn Bhd (1383901-A)
                                        Central No:46, Jalan Puj 8/6,
                                        Taman Puncak Jali, 43300 Seri Kembangan Selangor.</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">booking.themovers.my@gmail.com</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle"> 012-5996577</p>
                                </div>
                            </div> <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle"> 012-3244261 (Hotline)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Perak</h5>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">The Movers Online (M) Sdn Bhd (1383901-A)
                                        Perak No. 276, Jalan Sultan Azlan Shah, Taman Mansion, 31650 Ipoh, Perak</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">booking.themovers.my@gmail.com</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">017-7447212</p>
                                </div>
                            </div> <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-3244261 (Hotline)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Melaka</h5>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">The Movers Online (M) Sdn Bhd (1383901-A)
                                        Melaka DT 660, Jalan Bukit Tambun Perdana 13, Taman Bukit Tambun Perdana, Durian Tunggal 76100, Melaka</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">booking.themovers.my@gmail.com</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-7357212</p>
                                </div>
                            </div> <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-3244261 (Hotline)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-12">
                            <h5 className="footer-quick">Johor</h5>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">The Movers Online (M) Sdn Bhd (1383901-A)
                                        Johor-Singapore 53-B Jalan Puteri 2/1, Taman Puteri Wangsa 81800 Ulu Tiram Johor</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">booking.themovers.my@gmail.com</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-3445193</p>
                                </div>
                            </div> <div className="d-flex">
                                <div className="me-3">
                                    <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                </div>
                                <div className="">
                                    <p className="addresslinestyle">012-3244261 (Hotline)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="https://wa.me/+60123244261" target="_blank" className="whatsup-icon-bottom-style">
                        <img src={Whatsapp} className="footer-logos3" />
                    </a>
                </div>
            </footer>
        </>
    )
}

export default Footer
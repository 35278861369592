import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { BudgetImages } from '../../Utilities/images';
import { toast } from 'react-toastify';
import { BookingDetailsMaster } from "../../Utilities/bookingDetailsMaster";
import { getAxios } from "../../Utilities/commonAxios";
import Manimgicon from '../../img/manpower-icon.png'
import Timeicon from '../../img/time-icon.png'
import _ from "lodash";
import { manageLoader } from "../../Reduxstore/loaderSlice";
import { useDispatch, useSelector } from 'react-redux';
import { DescriptionText } from "../../Utilities/confirmationAlert";
import { StringBase64Encode } from "../../Utilities/stringBase64Encode";
import { bookingPreviewed } from "../../Reduxstore/bookingSlice";

const Initiatebooking = () => {
    let isAuthenticated = useSelector(state => state.auth)
    let isBooked = useSelector(state => state.booking)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let initialBookingDetails = (isBooked?.initialBookingDetails) ? (isBooked?.initialBookingDetails) : (null);
    let previewBookingDetails = (isBooked?.previewBookingDetails) ? (isBooked?.previewBookingDetails) : (null);
    // booking
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [bookingAmount, setBookingAmount] = useState((Object.keys(previewBookingDetails).length > 0) ? (Number(previewBookingDetails?.amount)) : (0));
    // budget | premium | disposal
    const [vehicleLists, setVehicleLists] = useState([])
    const [selectedVehicleDetails, setSelectedVehicleDetails] = useState(previewBookingDetails?.details || {})

    const handleVehicleSelected = async (vehicle) => {
        setSelectedVehicleDetails(vehicle)
        await calculateBookingDetails(vehicle)
        if (vehicle.description != "") {
            DescriptionText(vehicle.tonne, vehicle.description)
        }
    }


    // man power
    const [manPowerLists, setManPowerLists] = useState([])
    const [manPowerBasePrice, setManPowerBasePrice] = useState(0)
    const getListFromAPI = async () => {
        if (initialBookingDetails?.bookingType === "budget") {
            dispatch(manageLoader(true));
            await getAxios({
                url: `${process.env.REACT_APP_API_URL}/budget_moving_detail`,
            }).then((response) => {
                dispatch(manageLoader(false));
                if (response?.data?.Api_success === "true") {
                    setVehicleLists(response?.data?.budget_package)
                }
            });
        } else if (initialBookingDetails?.bookingType === "premium") {
            dispatch(manageLoader(true));
            await getAxios({
                url: `${process.env.REACT_APP_API_URL}/premium_package_detail`,
            }).then((response) => {
                dispatch(manageLoader(false));
                if (response?.data?.Api_success === "true") {
                    setVehicleLists(response?.data?.premium_package)
                }
            });
        } else if (initialBookingDetails?.bookingType === "disposal") {
            dispatch(manageLoader(true));
            await getAxios({
                url: `${process.env.REACT_APP_API_URL}/disposal_package_detail`,
            }).then((response) => {
                dispatch(manageLoader(false));
                if (response?.data?.Api_success === "true") {
                    setVehicleLists(response?.data?.disposal_package)
                }
            });
        } else if (initialBookingDetails?.bookingType === "manpower") {
            dispatch(manageLoader(true));
            await getAxios({
                url: `${process.env.REACT_APP_API_URL}/manpower_package_detail`,
            }).then((response) => {
                dispatch(manageLoader(false));
                if (response?.data?.Api_success === "true") {
                    setManPowerLists(response?.data?.manpower_package)
                    setManPowerBasePrice(response?.data?.default_manpower_amount)
                }
            });
        }
    }
    useEffect(() => {
        if (initialBookingDetails?.bookingType) getListFromAPI()
    }, [])
    const calculateBookingDetails = (vehicle) => {
        if (initialBookingDetails?.bookingType === "budget") {
            let bookingDistance = Number(initialBookingDetails.distance);
            let bookingDistanceinKm = Math.round(bookingDistance);
            let basePrice = Number(vehicle.base_price);
            if (bookingDistanceinKm <= 10) {
                setBookingAmount(Number(basePrice))
            } else {
                let extraInKM = bookingDistanceinKm - 10;
                if (bookingDistanceinKm > 10 & bookingDistanceinKm <= 60) {
                    let extraPrice = Math.round(Number(extraInKM) * (Number(vehicle?.add_on_price_1)));
                    setBookingAmount(Number(basePrice) + Number(extraPrice))
                } else if (bookingDistanceinKm > 60) {
                    let extraPrice = Math.round(Number(extraInKM) * (Number(vehicle?.add_on_price_2)));
                    setBookingAmount(Number(basePrice) + Number(extraPrice))
                } else {
                    setBookingAmount(0)
                }
            }
        } else if (initialBookingDetails?.bookingType === "premium") {
            let bookingDistance = Number(initialBookingDetails.distance);
            let bookingDistanceinKm = Math.round(bookingDistance);
            let basePrice = Number(vehicle.base_price);
            if (bookingDistanceinKm <= 60) {
                setBookingAmount(Number(basePrice))
            } else {
                let extraInKM = bookingDistanceinKm - 60;
                let extraPrice = Math.round(Number(extraInKM) * (Number(vehicle?.above60km)));
                setBookingAmount(Number(basePrice) + Number(extraPrice))
            }
        } else if (initialBookingDetails?.bookingType === "disposal") {
            let basePrice = Number(vehicle.base_price);
            setBookingAmount(Number(basePrice))
        }
    }
    // manpower - count
    const [manPowerCount, setManPowerCount] = useState(2)
    const handleManPowerCount = (type) => {
        if (type === "minus") setManPowerCount(manPowerCount - 1)
        else if (type === "plus") setManPowerCount(manPowerCount + 1)
    }
    // manpower - duration
    const [manPowerDuration, setManPowerDuration] = useState(5)
    const [ismanPowerDurationFullDay, setIsmanPowerDurationFullDay] = useState(false)
    const [manPowerDescription, setManPowerDescription] = useState('')
    const handleManPowerDuration = (type) => {
        if (type === "minus") setManPowerDuration(manPowerDuration - 1)
        else if (type === "plus") setManPowerDuration(manPowerDuration + 1)
    }
    const handleManPowerDurationFullDay = () => setIsmanPowerDurationFullDay(!ismanPowerDurationFullDay)
    const calculateManPowerDetails = () => {
        if (manPowerLists.length > 0) {
            let selectedManPowerList = (_.filter(manPowerLists, ["manpower", `${manPowerCount} ManPower`]))[0]
            if (ismanPowerDurationFullDay) {
                setBookingAmount(Number(selectedManPowerList.wholeday9amto5pm) + manPowerBasePrice)
            } else {
                if (manPowerDuration === 2) setBookingAmount(Number(selectedManPowerList.two_hours) + manPowerBasePrice)
                else if (manPowerDuration === 3) setBookingAmount(Number(selectedManPowerList.three_hours) + manPowerBasePrice)
                else if (manPowerDuration === 4) setBookingAmount(Number(selectedManPowerList.four_hours) + manPowerBasePrice)
                else if (manPowerDuration === 5) setBookingAmount(Number(selectedManPowerList.five_hours) + manPowerBasePrice)
            }
        }
    }
    useEffect(() => {
        calculateManPowerDetails()
    }, [manPowerLists, manPowerCount, manPowerDuration, ismanPowerDurationFullDay])
    // page submit
    const toggleNextBookingStage = async () => {
        let bookingData = {}
        setIsFormValidated(true)
        if (initialBookingDetails?.bookingType === "budget" || initialBookingDetails?.bookingType === "premium" || initialBookingDetails?.bookingType === "disposal") {
            if (Object.keys(selectedVehicleDetails).length === 0 || bookingAmount === 0) {
                toast.error('Invalid vehicle type!')
            } else {
                bookingData = {
                    amount: bookingAmount,
                    details: selectedVehicleDetails
                }
                let bookingPreviewedResponse = await dispatch(bookingPreviewed(bookingData));
                if (bookingPreviewedResponse) {
                    navigate('/confirm-booking')
                } else {
                    toast.error('Invalid booking details!')
                }
            }
        } else if (initialBookingDetails?.bookingType === "manpower") {
            if (manPowerCount === 0 || bookingAmount === 0) {
                toast.error('Invalid count/duration!')
            } else {
                bookingData = {
                    amount: bookingAmount,
                    details: {
                        count: manPowerCount,
                        duration: ismanPowerDurationFullDay ? (8) : (manPowerDuration),
                        description: manPowerDescription,
                        base_price: manPowerBasePrice
                    }
                }
                let bookingPreviewedResponse = await dispatch(bookingPreviewed(bookingData));
                if (bookingPreviewedResponse) {
                    navigate('/confirm-booking')
                } else {
                    toast.error('Invalid booking details!')
                }
            }
        }
    }
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="about-heading">{(initialBookingDetails?.bookingType) ? (BookingDetailsMaster[initialBookingDetails.bookingType]['label']) : ('')}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card budect-booking-card">
                                {(initialBookingDetails?.bookingType === "budget" || initialBookingDetails?.bookingType === "premium" || initialBookingDetails?.bookingType === "disposal") && (
                                    <>
                                        <div className="card-header budgect-header">
                                            <h4 className="budect-heading-style">Select Vehicle</h4>
                                        </div>
                                        <div className="register-box-scroll">
                                            <div className="card-body card-body-budgect">
                                                <div className="row">
                                                    {vehicleLists && vehicleLists?.map((vehicleList, index) =>
                                                        <div className="col-md-3 col-6 mb-3" key={index} onClick={() => handleVehicleSelected(vehicleList)}>
                                                            <div className={`budgect-inner-style ${(selectedVehicleDetails?.id === vehicleList.id) ? ('active') : ('')}`}>
                                                                <img src={BudgetImages[`${vehicleList.id}.png`]} className="inner-budgect-img" />
                                                                <h4 className='budgect-sub-text'>{vehicleList.tonne}</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(isFormValidated && Object.keys(selectedVehicleDetails).length === 0) ? (
                                                        <p className="distance-style">Please select vehicle type</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="budect-calc">
                                            <div className='row'>
                                                <div className='col-xl-8 col-md-6 col-12 d-flex align-items-stretch'>
                                                </div>
                                                <div className='col-xl-4 col-md-6 col-12 d-flex align-items-stretch'>
                                                    <div className='budect-calc-right'>
                                                        <div className="row">
                                                            <div className="col-md-6 col-6">
                                                                <h5 className='budgect-total'>Total Amount</h5>
                                                            </div>
                                                            <div className="col-md-6 col-6">
                                                                <h5 className='budgect-total1'>MYR {bookingAmount}</h5>
                                                            </div>
                                                            {/* <div className="col-md-6 col-6">
                                                                    <h5 className='budgect-total'></h5>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <h5 className='budgect-total1'>{bookingAmount}</h5>
                                                                </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </>
                                )}
                                {initialBookingDetails?.bookingType === "manpower" && (
                                    <>
                                        <div className="man-box-style">
                                            <div className="row">
                                                <div className="col-md-2 col-12 mb-2">
                                                </div>
                                                <div className="col-md-4 col-12 mb-2 d-flex align-items-stretch">
                                                    <div className="details-card">
                                                        <div className="details-card-header text-center">
                                                            <h5>Select ManPower</h5>
                                                        </div>
                                                        <div className="detailscard-body">
                                                            <div className="row">
                                                                <div className="col-md-12 col-12 text-center">
                                                                    <img src={Manimgicon} className='man-only-img' />
                                                                </div>
                                                                <div className="col-md-12 col-12 text-center mt-2">
                                                                    <div class="input-group mt-2 mb-4">
                                                                        <div class="input-group-prepend">
                                                                            <button class={`btn btn-outline-secondary add-custom-btn ${(manPowerCount === 2) ? ('disabled') : ('')}`} type="button" id="decrementBtn" onClick={() => handleManPowerCount('minus')}>-</button>
                                                                        </div>
                                                                        <input type="text" id="inputValue" class="form-control text-center" aria-label="Input value" value={manPowerCount} />
                                                                        <div class="input-group-append">
                                                                            <button class={`btn btn-outline-secondary add-custom-btn ${(manPowerCount === 5) ? ('disabled') : ('')}`} type="button" id="incrementBtn" onClick={() => handleManPowerCount('plus')}>+</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 mb-2 d-flex align-items-stretch">
                                                    <div className="details-card">
                                                        <div className="details-card-header text-center">
                                                            <h5>Duration</h5>
                                                        </div>
                                                        <div className="detailscard-body">
                                                            <div className="row">
                                                                <div className="col-md-12 col-12 text-center mb-0">
                                                                    <img src={Timeicon} className='man-only-img1' />
                                                                </div>
                                                                {!ismanPowerDurationFullDay && (
                                                                    <div className="col-md-12 col-12 text-center mt-0">
                                                                        <div class="input-group mt-4 mb-2">
                                                                            <div class="input-group-prepend">
                                                                                <button class={`btn btn-outline-secondary add-custom-btn ${(manPowerDuration === 2) ? ('disabled') : ('')}`} type="button" id="decrementBtn" onClick={() => handleManPowerDuration('minus')}>-</button>
                                                                            </div>
                                                                            <input type="text" id="inputValue" class="form-control text-center" aria-label="Input value" value={manPowerDuration} />
                                                                            <div class="input-group-append">
                                                                                <button class={`btn btn-outline-secondary add-custom-btn ${(manPowerDuration === 5) ? ('disabled') : ('')}`} type="button" id="incrementBtn" onClick={() => handleManPowerDuration('plus')}>+</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="col-md-12 col-12 mt-2">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox" value="" checked={(ismanPowerDurationFullDay) ? ('checked') : ('')} id="flexCheckChecked" onChange={handleManPowerDurationFullDay} />
                                                                        <label class="form-check-label" for="flexCheckChecked">
                                                                            For full day 9:Am to 5:00 Pm
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3 d-flex'>
                                                <div className="col-md-2 col-12">
                                                </div>
                                                <div className='col-md-8 col-12'>
                                                    <div class="mb-3">
                                                        <label for="manPowerDescription" class="form-label">Remarks (Optional)</label>
                                                        <textarea class="form-control" id="manPowerDescription" rows="3" value={manPowerDescription} onChange={(e) => setManPowerDescription(e.target.value)}></textarea>
                                                    </div>
                                                    <div className='remark-total-box'>
                                                        <div className='row'>
                                                            <div className='col-md-6 col-6'>
                                                                <h6 className='mb-0 text-end'>Total Amount</h6>
                                                            </div>
                                                            <div className='col-md-6 col-6'>
                                                                <h4 className='tot-style'>MYR {bookingAmount}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className='row budgect-top-section'>
                        <div className="col-md-6 col-6 ">
                            <Link to={'/'} className='bud-link-style1'><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link>
                        </div>
                        <div className="col-md-6 col-6 text-end">
                            <Link to="#" className='bud-link-style1' onClick={toggleNextBookingStage}>Next <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Initiatebooking
import { Link } from 'react-router-dom';
import Aboutimg from '../img/registerlogo.png';
import Abouimg5 from '../img/The Movers Online_lala move.jpg';
import Abouimg1 from '../img/The Movers Online_lorry for rent.jpg';
import Abouimg4 from '../img/The Movers Online_the lorry.jpg';
import Abouimg2 from '../img/the_movers_online_lori_sewa.png';
import Abouimg3 from '../img/the_movers_online_packers_movers.jpg';
const About = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h3 className="about-heading">About Us</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h3 className="main-heading-style">The Movers Online Story</h3>
                            <p className="common-para-style">The Movers Online was founded in 2017 by Sutarthaan Nagalingam after 8 years of working in moving & Storage industry. Passionate to create a product that helped the customers, The Movers Online was born with a strong emphasis on digital presence.</p>

                            <p className="common-para-style"> After setting up his own Relocation company in 2011 (Optimum Logistic), Sutarthaan Nagalingam noticed an opportunity to progress his careers whilst there was a gap in the market. Then created a website [www.themovers.my] that focused on allowing customers to view transparent pricing online, as well as providing a lead generation service.</p>
                            <p className="common-para-style"> The Movers Online grown our team and services since 2011, and continue to bring together the best moving professionals in our verified partner network. We are a small but strong team of creative individuals who work hard every day to better your moving house experience.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <h4 className="seemless-color">About and what we offer for you to enjoy a seamless moving experience</h4>
                            <p className="about-sub-title">by The Movers Online | jan 23,2024</p>
                            <img src={Aboutimg} className='about-img-size' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <h4 className="main-heading-style">What do The Movers Do?</h4>
                            <p className="common-para-style"> The Movers Online was founded in 2017 by Sutarthaan Nagalingam after 8 years of working in moving & Storage industry. Passionate to create a product that helped the customers, The Movers Online was born with a strong emphasis on digital presence.</p>
                            <p className="common-para-style">The Movers Online was founded in 2017 by Sutarthaan Nagalingam after 8 years of working in moving & Storage industry. Passionate to create a product that helped the customers, The Movers Online was born with a strong emphasis on digital presence. </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12 col-12'>
                            <h4 className='small-heading-size'>You should hire a The Movers Online if:</h4>
                            <p className="common-para-style-reset">You’re moving from a 1+ bedroom house or flat.</p>
                            <p className="common-para-style-reset">You’re moving heavy and bulky furniture.</p>
                            <p className="common-para-style-reset">You have specialist and fragile antique items.</p>
                            <p className="common-para-style-reset">You want your items to be packed.</p>
                            <p className="common-para-style-reset">You have furniture that needs to be dismantled.</p>
                            <p className="common-para-style">There are certain situations where it’s a must to hire a moving company to help out. Don’t be afraid to talk to the movers before hand to know both on the same page.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12 col-12'>
                            <h4 className='small-heading-size'>The Movers Online Services Include:</h4>
                            <p className="common-para-style mb-1">1 Packing and unpacking.</p>
                            <p className="common-para-style mb-1">2 Furniture dismantling and assembling.</p>
                            <p className="common-para-style mb-1">3 Antique art packing.</p>
                            <p className="common-para-style mb-1">4 Piano & Safe Box packing and moving.</p>
                            <p className="common-para-style mt-2">If your house move requires specific in-depth knowledge and understanding, then don’t be afraid to ask The Movers Online. We do our best to tailor your move to meet your specific requirements. So, make sure you include any specific requirements when requesting a quotation.</p>
                            <p className="common-para-style mt-2">It’s not recommended to do your own house shifting. It may be tempting to do it yourself and avoid paying out more money for your house move, but hiring a moving company to do the hard work for you will help you to save money in the long run. You don’t want to run the risk of damaging sentimental items or scratching antique furniture.</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className='col-md-4 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                {/* <img src={Abouimg1} className="card-img-top" alt="..." /> */}
                                <img src={Abouimg1} alt="..." />
                                <div className="card-body">
                                    <p className="common-para-style ab-min-height-style">7 tips penting yang perlu diamalkan sebelum pindah rumah</p>
                                    <div className='text-center'>
                                        <Link to='/tipsPending'
                                            className="btn btn-primary custom-more"
                                        // onClick={(event) => event.preventDefault()

                                        // }
                                        >More details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                {/* <img src={Abouimg2} className="card-img-top" alt="..." /> */}
                                <img src={Abouimg2} alt="..." />
                                <div className="card-body">
                                    <p className="ab-min-height-style common-para-style">Moving House ?</p>
                                    <Link to={'/houseMoving'} className="btn btn-primary custom-more">More details</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                {/* <img src={Abouimg3} className="card-img-top" alt="..." /> */}
                                <img src={Abouimg3} alt="..." />
                                <div className="card-body">
                                    <p className="ab-min-height-style common-para-style">13 TIPS :Tips pindah rumah</p>
                                    <Link to={'/tipsPindha'} className="btn btn-primary custom-more">More details</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                {/* <img src={Abouimg4} className="card-img-top" alt="..." /> */}
                                <img src={Abouimg4} alt="..." />
                                <div className="card-body">
                                    <p className="ab-min-height-style common-para-style">Pindah Rumah – Lori Sewa Berpatutan</p>
                                    <Link to={'/pindhaRumha'} className="btn btn-primary custom-more">More details</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                {/* <img src={Abouimg5} className="card-img-top" alt="..." /> */}
                                <img src={Abouimg5} alt="..." />
                                <div className="card-body">
                                    <p className="ab-min-height-style common-para-style">Dos and Don’ts during your House Relocation | Moving House</p>
                                    <Link to={'/dosdont'} className="btn btn-primary custom-more">More details</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-12'>
                            <h4 className="small-heading-size">Reviews</h4>
                            <p className="common-para-style mt-2">Check out the reviews for the the movers online. We the movers online have a majority of good reviews.</p>
                            <h3 className='small-heading-size'>
                                No Hidden Fees</h3>
                            <p className="common-para-style mt-2">We provide you with an accurate quote that includes the final cost including any services you’ve added. We provide a breakdown of the quote, that way you can see exactly what they are charging you for.</p>
                            <h3 className='small-heading-size'>
                                Experience</h3>
                            <p className="common-para-style mt-2">To give you peace of mind, we the movers online have 12 years of experience behind us.Started from your typical Lori Sewa Company with advertising via sign Board everywhere we now had fully online. If your move is an awkward or difficult one, don’t worry we are ready to give you a hand.</p>
                            <h3 className='small-heading-size'>
                                Staff</h3>
                            <p className="common-para-style mt-2">A reputable moving company we have highly-trained and qualified staff that deliver a first-class service. The Movers Online has reviews that mention our staff’s behavior, this could help to influence your decision. You want to know if we will treat your belongings as if were our own, YES we definitely will.</p>
                            <h3 className='small-heading-size'>
                                Save Time</h3>
                            <p className="common-para-style mt-2">By using The Movers Online we will do the hard work for you, from packing up your belongings to unpacking at your new house. This will save you a significant amount of time.</p>
                            <h3 className='small-heading-size'>
                                Fully Equipped</h3>
                            <p className="common-para-style mt-2">We have the essential equipment in place to ensure your personal belongings don’t get damaged throughout the move. We know how to secure your items in thelorry so they stay in place for the duration of the journey.</p>
                        </div>
                    </div>
                    <h3 className='small-heading-size'>
                        Contact Us !</h3>
                    <div className='contact-box-style'>
                        <p className="common-para-style mb-2">If you want to get in touch with us regarding any of our services or for general enquiries, please call us on +60123244261 , email us at booking.themovers.my@gmail.com my or write to us at <strong className='inner-style'>The Movers Online , No:2 , Jalan Puj 9/23 , Taman Puncak Jalil , 43300 Seri Kembangan.</strong></p>
                        <p className="common-para-style mb-2">If you would like a quote for a Moving Services , Reach out our Customer service or please visit  <Link to={'/'}> www.themovers.my.</Link> </p>
                        <p className="common-para-style mb-0">For all press and media enquiries, contact info@themovers.my or you can call   +60123244261</p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default About;
import Serviceimg1 from '../../img/The Movers Online_jom pindah.png'
import Readyimg from '../../img/ready-img.png'

const BudgetMoving = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="local-heading"> Local Moving</h4>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="col-md-4 col-12">
                            <img src={Serviceimg1} className='w-100' />
                        </div>
                        <div className="col-md-5 col-12">
                            <h5 className='price-style1'>PRICE FROM: <span className='pricecolor-style1'>RM 120 </span></h5>
                            <h6 className='pricecolor-style1'>What Included in Local Moving Package</h6>
                            <p className='mb-1'>• Lorry of your choice – 1 tonne (10ft) / 3 tonne (14ft) / 5 tonne (17ft)</p>
                            <p className='mb-1'>• Trained & Skilled Approved Crew (2 Man Power-1Tonne Package)(3 Man Power-3&5Tonne Package)</p>
                            <p className='mb-1'>• WITHIN <strong> 60KM</strong> Around <strong>Klang valley </strong></p>
                            <h6 className='pricecolor-style1 mt-2'>How it Works</h6>
                            <h5 className='requst-color mb-2'>Submit your request</h5>
                            <p className='mb-1'>• Our Customer Service Representative will contact you to schedule an appointment.</p>
                            <p className='mb-1'>• Pay via Online Banking or directly to the vendor upon completion of the service.</p>
                        </div>
                        <div className="col-md-3 col-12 d-none d-xl-block">
                            <img src={Readyimg} className='w-100' />
                            <p className='mb-1 mt-3'>No 2, Jalan PUJ 9/23, Taman Puncak Jalil, 43300 Seri Kembangan, Selangor</p>
                            <p className='mb-1 budgect-color-style'>+60123244261</p>
                            <p className='mb-1 budgect-color-style'>booking.themovers.my@gmail.com</p>
                            <h5 className='additional-style mb-2'>Got Any Question?</h5>
                            <p>If you are having any questions, please feel free to ask.</p>
                        </div>
                    </div>

                    <div className="row seprate-top-style">
                        <div className="col-md-8 col-12">
                            <h5 className='pricecolor-style1 mb-2'>Things to Take Note</h5>
                            <p className='mb-1'>• Our local moving packages do not include wrapping, packing or supply of boxes. If these services are required, our Crew will provide you with a separate quote before they start their work.</p>
                            <p className='mb-1'>• If you prefer an all-inclusive moving service, please book for other available packages .</p>
                            <p className='mb-1'>• Not applicable for moving of piano or safeboxes – Any Heavy Item weight more than 70 KG</p>
                            <p className='mb-1'>• Loading Bay Charges is Applicable in the Event the Distance is more than 15m from/to the loading lift or to the unit</p>
                            <p className='mb-1'>• Our local moving package does not cover NOLIFT Charges [MYR 30/Floor For 1Tonne Package] [ MYR 70/Floor For 3 Tonne & MYR 100/Floor For 5 Tonne Package]</p>
                            <p className='mb-1'>• Building Management Approval Letter to be obtained Prior to the Moving Day</p>
                            <p className='mb-1'>• Movers Have Rights to Charge 30% of the Booking Amount as cancellation Fee for cancellation lesser than 24 hours</p>
                            <p className='mb-1'>• If you are planning for interstate moving, it’s recommended to go for our LONG DISTANCE MOVING PACKAGE</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <h5 className='pricecolor-style1 mb-2 mt-3'>Extra Services</h5>
                            <p className='mb-1'>• TAIL GATE ( ADDITIONAL MYR 100 ) for all packages</p>
                            <p className='mb-1'>• Shrink Wrap Per Item ( MYR 15)</p>
                            <p className='mb-1'>• Bubble wrap Per item ( MYR 25 )</p>
                            <p className='mb-1'>• Boxes Packing Per Item ( MYR 15 )</p>
                            <p className='mb-1'>• Bubble wrap bundle package ( MYR 200/ Roll ) can be used to wrap almost 10 -12 Medium sized items</p>
                            <p className='mb-1'>• Shrink wrap Bundle Package (MYR 80/ Roll ) can be used to wrap almost 6-8 Medium sized furniture</p>
                            <p className='mb-1'>• Boxes Bundle package – MYR 130 for 10 Boxes</p>
                            <p className='mb-1'>• Dismantling & Reassembling Furnitures ( MYR 40/SMALL ITEM ) (MYR 150/BIG ITEM) If you have more than 3 big item, it’s recommended to go for our SIGNATURE PACKAGE</p>
                        </div>
                        <div className="col-md-3 col-12 d-block d-xl-none mobile-d-block">
                            <img src={Readyimg} className='w-100' />
                            <p className='mb-1 mt-3'>No 2, Jalan PUJ 9/23, Taman Puncak Jalil, 43300 Seri Kembangan, Selangor</p>
                            <p className='mb-1 budgect-color-style'>+60123244261</p>
                            <p className='mb-1 budgect-color-style'>booking.themovers.my@gmail.com</p>
                            <h5 className='additional-style mb-2'>Got Any Question?</h5>
                            <p>If you are having any questions, please feel free to ask.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BudgetMoving
import { Link } from 'react-router-dom'
import Service1 from '../img/The Movers Online_jom pindah.png'
import Service3 from '../img/The Movers Online_long distance moving.jpg'
import Service4 from '../img/The Movers Online_man power.png'
import Service2 from '../img/the_movers_online.jpg'

const Services = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h3 className="about-heading">Our Services</h3>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className='col-md-3 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                <img src={Service1} className="service-box" alt="..." />
                                <div className="card-body">
                                    <h4 className='service-heading'>Budget Moving</h4>
                                    <div className='text-center'>
                                        <Link to={'/budget'} className="btn btn-primary custom-more-service">More details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                <img src={Service2} className="service-box" alt="..." />
                                <div className="card-body">
                                    <h4 className='service-heading'>Premium Moving</h4>
                                    <div className='text-center'>
                                        <Link to={'/premimum'} className="btn btn-primary custom-more-service">More details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                <img src={Service3} className="service-box" alt="..." />
                                <div className="card-body">
                                    <h4 className='service-heading'>Disposal Service</h4>
                                    <div className='text-center'>
                                        <Link to={'/disposal'} className="btn btn-primary custom-more-service">More details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-12 about-card-box d-flex align-items-stretch'>
                            <div className="card box-style">
                                <img src={Service4} className="service-box" alt="..." />
                                <div className="card-body">
                                    <h4 className='service-heading'>Manpower Service</h4>
                                    <div className='text-center'>
                                        <Link to={'/manpower'} className="btn btn-primary custom-more-service">More details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Services;
import { Link } from "react-router-dom";
import Headerlogo from '../img/registerlogo.png'
const ContactUs = () => {
    return (
        <>
            <section className="common-top-style mybooking-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h4 className="about-heading mb-2">Contact Us</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12 mt-3">
                            <form>
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label custom-label">Name</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label custom-label">Email</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label custom-label">Mobile</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label custom-label">Subject</label>
                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label custom-label">Describtion</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="4"></textarea>
                                </div>
                                <div className="">
                                    <Link to={'/'} className="btn btn-primary check-btn">Submit</Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-12 mt-3">
                            <div className="contact-box-info">
                                <h5 className="footer-quick">Address</h5>
                                <div className="d-flex mb-2">
                                    <div className="me-2">
                                        <i className="fa fa-map-marker marker-size" aria-hidden="true"></i>
                                    </div>
                                    <div className="">
                                        <p className="addresslinestyle">No:2 , Jalan Puj 9/23 ,<br />
                                            Taman Puncak Jalil , 43300 Seri Kembangan.</p>
                                    </div>
                                </div>
                                <h5 className="footer-quick">Mail Id</h5>
                                <div className="d-flex mb-2">
                                    <div className="me-2">
                                        <i className="fa fa-envelope-o marker-size1" aria-hidden="true"></i>
                                    </div>
                                    <div className="">
                                        <p className="addresslinestyle">booking.themovers.my@gmail.com</p>
                                    </div>
                                </div>
                                <h5 className="footer-quick">Mobile</h5>
                                <div className="d-flex mb-2">
                                    <div className="me-2">
                                        <i className="fa fa-phone marker-size" aria-hidden="true"></i>
                                    </div>
                                    <div className="">
                                        <p className="addresslinestyle"> +60123244261</p>
                                    </div>
                                </div>
                                <h5 className="footer-quick">Company</h5>
                               <img src={Headerlogo} className='mainheader-logo'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
export default ContactUs;
import Manpower11 from '../../img/man1.png';
import Manpower12 from '../../img/man2.png';
import Manpower13 from '../../img/man3.png';
import Manpower14 from '../../img/man4.png';
import Serviceimg3 from '../../img/The Movers Online_man power.png';
const ManpowerMoving = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="local-heading mb-0">MAN POWER SERVICE </h4>
                        </div>
                    </div>

                    <div className="row"> <div className="col-md-4 col-12">
                        <img src={Serviceimg3} className='w-100' />
                    </div>
                        <div className="col-md-5 col-12">

                            <h5 className='pricecolor-style1 mb-2 mt-3'>How it Works</h5>
                            <p className='mb-1'>• <strong> Share your Shifting Requirement – </strong>Help us by providing when and where do you want to move</p>
                            <p className='mb-1'>• <strong>Receive Free Instant Quote –</strong> Get Guaranteed Lowest Price Quoted for your shifting Instantly
                            </p>
                            <p className='mb-1'>• <strong>Assign Quality Service Expert (Move Manager) –</strong> To ensure Safe Relocation Quality Service expert will
                                be allotted</p>
                            <p className='mb-1'>• <strong>Leave the Heavy Lifting to Us -</strong> Enjoy Hassle-Free on time Movement of your House Hold Items</p>
                            <p className='mb-1'>• <strong>Payment - </strong>Pay via Online Banking or directly to the vendor upon completion of the service.
                            </p>

                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <h5 className='pricecolor-style1 mb-2 mt-3'>THINGS TO TAKE NOTE</h5>
                        </div>
                        <div className='col-md-12'>
                            <p className='mb-1'>• Our packages <strong>do not include </strong>Wrapping, Packing or Supply of Boxes. If these services are
                                required, our Crew will provide you with a separate quote before they start their work.</p>
                            <p className='mb-1'>• Not applicable for moving of piano or safe boxes - Any Heavy Item weight more than 50 KG – Charges
                                May Applies - Ranging from MYR80 – MYR250
                            </p>
                            <p className='mb-1'>• Loading Bay Charges is Applicable in the Event the Distance is more than 15m from/to theloading
                                lift or to the unit – Ranging from MYR50 - MYR250 (Subjective to the Distance)</p>
                            <p className='mb-1'>• Package Does not cover NOLIFT Charges [MYR30/Floor For 1Tonne Package] [ MYR70/FloorFor
                                3Tonne Package] [MYR100/Floor For 5Tonne Package]
                            </p>
                            <p className='mb-1'>• Building Management Approval Letter to be obtained Prior to the Moving Day</p>
                            <p className='mb-1'>• Movers Have Rights to Charge 30% of the Booking Amount as cancellation Fee for
                                cancellation lesser than 48hours</p>
                            <p className='mb-1'>• One Time Transport fee is Charged - MYR50
                            </p>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12 col-12">
                            <h5 className='pricecolor-style1 mb-2 mt-3'>Pricing</h5>
                            <div className='table-responsive'>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Skilled & Trained Man Power</th>
                                            <th className='text-center'>2 -
                                                HOURS</th>
                                            <th className='text-center'>3 -
                                                HOURS</th>
                                            <th className='text-center'>4 -
                                                HOURS</th>
                                            <th className='text-center'>5 -
                                                HOURS</th>
                                            <th className='text-center'>WHOLE DAY 9
                                                AM - 5PM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>2 Man Power</th>
                                            <td className='text-center'>RM200</td>
                                            <td className='text-center'>RM300</td>
                                            <td className='text-center'>RM400</td>
                                            <td className='text-center'>RM500</td>
                                            <td className='text-center'>RM600</td>
                                        </tr>
                                        <tr>
                                            <th>3 Man Power</th>
                                            <td className='text-center'>RM300</td>
                                            <td className='text-center'>RM400</td>
                                            <td className='text-center'>RM500</td>
                                            <td className='text-center'>RM600</td>
                                            <td className='text-center'>RM700</td>
                                        </tr>
                                        <tr>
                                            <th>4 Man Power</th>
                                            <td className='text-center'>RM400</td>
                                            <td className='text-center'>RM500</td>
                                            <td className='text-center'>RM600</td>
                                            <td className='text-center'>RM700</td>
                                            <td className='text-center'>RM800</td>
                                        </tr>
                                        <tr>
                                            <th>5 Man Power </th>
                                            <td className='text-center'> RM500</td>
                                            <td className='text-center'>RM600</td>
                                            <td className='text-center'>RM700</td>
                                            <td className='text-center'>RM800</td>
                                            <td className='text-center'>RM1,000</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className='col-12'>
                            <h5 className='pricecolor-style1 mb-2 mt-3 mb-5'>WHY US ?
                            </h5>
                        </div>
                        <div className="col-md-3 col-6 text-center mb-3">
                            <div className="col-12 text-center mnpw-div"><img src={Manpower11} className='manpower-style' /></div>
                            <h6 className='mt-3'>NO BROKER</h6>
                            <p>Moving at a price you can afford – No agent Fee</p>
                        </div>
                        <div className="col-md-3 col-6 text-center mb-3">
                            <div className="col-12 text-center mnpw-div"><img src={Manpower12} className='manpower-style' /></div>
                            <h6 className='mt-3'>DEDICATED MOVE MANAGER</h6>
                            <p>Exceeding expectations, one customer at a time</p>
                        </div>
                        <div className="col-md-3 col-6 text-center mb-3">
                            <div className="col-12 text-center mnpw-div"><img src={Manpower13} className='manpower-style' /></div>
                            <h6 className='mt-3'>TRAINED – SKILLED CREW</h6>
                            <p>Expertly packing and moving your belongings</p>
                        </div>
                        <div className="col-md-3 col-6 text-center mb-3">
                            <div className="col-12 text-center mnpw-div"><img src={Manpower14} className='manpower-style' /></div>
                            <h6 className='mt-3'>Quality Assurance</h6>
                            <p>Safe and Reliable Packaging and Moving Services</p>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default ManpowerMoving
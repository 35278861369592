import Serviceimg3 from '../../img/The Movers Online_long distance moving.jpg'
import Readyimg from '../../img/ready-img.png'

const DisposalMoving = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="local-heading">Disposal Service</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <img src={Serviceimg3} className='w-100' />
                        </div>
                        <div className="col-md-5 col-12">
                            <h5 className='price-style1'>PRICE FROM: <span className='pricecolor-style1'>RM 250</span></h5>
                            <h6 className='pricecolor-style1'>What Included in Disposal Service Package</h6>
                            <p className='mb-1'>• Lorry of your choice – 1 tonne (10 ft) / 3 tonne (14 ft) / 5 tonne (17ft)</p>
                            <p className='mb-1'>• Trained & Skilled Approved Crew (2 Man Power-1 Tonne Package)(3 Man Power-3 & 5 Tonne Package)</p>
                            <p className='mb-1'>• Our Trained Crew to help you dispose comfortably (min 3 Man Power)</p>
                            <p className='mb-1'>• Clear your unwanted items and dispose at the licensed disposal facility</p>
                        </div>
                        <div className="col-md-3 col-12 d-none d-xl-block">
                            <img src={Readyimg} className='w-100' />
                            <p className='mb-1 mt-3'>No 2, Jalan PUJ 9/23, Taman Puncak Jalil, 43300 Seri Kembangan, Selangor</p>
                            <p className='mb-1 budgect-color-style'>+60123244261</p>
                            <p className='mb-1 budgect-color-style'>booking.themovers.my@gmail.com</p>
                            <h5 className='additional-style mb-2'>Got Any Question?</h5>
                            <p>If you are having any questions, please feel free to ask.</p>
                        </div>
                    </div>
                    <div className="row seprate-top-style">
                        <div className="col-md-8 col-12">
                            <p className='mb-1 mt-3'><strong> No Fret </strong>– Chill ! We will handle all the unwanted things for you</p>
                            <p className='mb-1 mt-3'><strong>Let’s Go Green </strong>– We will make sure your wastes are disposed properly – Get your booking confirmed within minutes</p>
                            <h5 className='pricecolor-style1 mb-2 mt-3'>How it Works</h5>
                            <p className='mb-1'>• Submit your request Our Customer Service Representative will contact you to schedule an appointment.</p>
                            <p className='mb-1'>• Pay via Online Banking or directly to the vendor upon completion of the service.</p>
                            <h5 className='pricecolor-style1 mb-2 mt-3'>Things to Take Note</h5>
                            <p className='mb-1'>• Only dry goods such as furniture and electrical items will be disposed.</p>
                            <p className='mb-1'>• Disposal of wet goods, household waste, industrial waste, construction & demolition debris, hazardous waste and large volume environmental soil cleanups may be rejected.</p>
                            <p className='mb-1'>• Additional cost may be incurred if more items / services are needed on the day of disposal.</p>
                            <p className='mb-1'>• Loading Bay Charges is Applicable in the Event the Distance is more than 15 m from / to the loading life or to the unit.</p>
                            <p className='mb-1'>• Our disposal package does not cover NOLIFT Charges [MYR 20 / Floor for 1 Tonne Package][MYR 50 / Floor for 3 Tonne & 5 Tonne Package]</p>
                            <p className='mb-1'>• Building Management Approval Letter to be obtained prior to the Moving Day.</p>
                            <p className='mb-1'>• Movers have rights to charge 30% of the booking amount as cancellation fee for cancellation lesser than 24 hours.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-12 d-block d-xl-none mobile-d-block">
                            <img src={Readyimg} className='w-100' />
                            <p className='mb-1 mt-3'>No 2, Jalan PUJ 9/23, Taman Puncak Jalil, 43300 Seri Kembangan, Selangor</p>
                            <p className='mb-1 budgect-color-style'>+60123244261</p>
                            <p className='mb-1 budgect-color-style'>booking.themovers.my@gmail.com</p>
                            <h5 className='additional-style mb-2'>Got Any Question?</h5>
                            <p>If you are having any questions, please feel free to ask.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DisposalMoving
import Abouimg1 from '../../img/The Movers Online_lala move.jpg';


const DosDont = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="local-heading">Dos and Don’ts during your House Relocation | Moving House</h4>
                            <p className='mb-1'>by The Movers Online | Sep 21, 2020</p>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="col-md-4 col-12">
                            <img src={Abouimg1} className='w-100' alt="The Movers Online" />
                        </div>
                        <div className="col-md-8 col-12">
                            <h5>Specialists for Special Items</h5>
                            <p className='mb-1'>• If you have any items that need extra care when handling, such as antiques, pianos or valuable artwork, then your moving company will be trained to help during your house relocation.</p>
                            <p className='mb-1'>• Professional movers have the experience needed to transport these
                                items safely.</p>
                            <h5>Don’t Overload Boxes</h5>
                            <p className='mb-1'>• One of the most common mistakes when packing is overloading boxes. If you reach a point where the box is half-full and starting to get heavy, then top it off with pillows or towels.</p>

                            <h5>Get Creative with Packing</h5>
                            <p className='mb-1'>• If you are using a packing service from the moving company for your house relocation, they will supply the boxes and materials. If you’re doing the packing yourself, get creative. Use laundry baskets, suitcases and large hold all bags to make the most of space.</p>

                            <h5>Don’t Leave Empty spaces</h5>
                            <p className='mb-1'>• Use packing paper, small items or towels to fill empty gaps in boxes. You
                                could even use kitchen paper, tea towels or old rags. The idea is to keep
                                your items securely in place during the relocation .</p>

                            <h5>Pack Room by Room</h5>
                            <p className='mb-1'>• A logical way to pack is doing room by room. Start with the rooms you
                                use the least such as the garage, store room and guest bedroom. Pack items that are out of season first and keep out items you’ll use up until moving day.</p>

                            <h5>Don’t Forget About Non – Allowable Items</h5>
                            <p className='mb-1'>• It’s important to know what your moving company will class as nonallowable items. Hazardous items like propane cylinders, paints, paint thinners and fireworks will not be allowed on the the lorry .</p>



                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default DosDont;

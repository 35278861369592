import Abouimg1 from '../../img/the_movers_online_packers_movers.jpg';

const TipsPindha = () => {
    return (
        <>
            <section className="banner-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="local-heading">13 TIPS :Tips pindah rumah</h4>
                            <p className='mb-1'>by The Movers Online | Sep 21, 2020</p>
                        </div>
                    </div>
                    <div className="row mb-0">
                        <div className="col-md-4 col-12">
                            <img src={Abouimg1} className='w-100' alt="The Movers Online" />
                        </div>
                        <div className="col-md-8 col-12"><p className='mb-1'>Anda ingin pindah rumah, tetapi tahukan anda caj yang dikenakan untuk mendapatkan servis pindnah rumah. The Movers Online telah mengumpulkan beberapa tips-tips pindah rumah sebagai rujukan.</p>
                            <h5>Tips 1: Kemas barang berperingkat</h5>
                            <p className='mb-1'>Jika korang seorang yang busy, baik korang kemas barang berperingkat. Maksudnya, korang takde masa yang banyak untuk kemas sekali gus. Korang guna masa terluang untuk kemas sikit-sikit tanpa penat yang melampau.</p>
                            <h6>Contoh :</h6>
                            <p className='mb-1'>• Kemas baju-baju yang jarang digunakan.</p>
                            <p className='mb-1'>• Kemas pinggan-pinggan yang tak digunakan.</p>
                            <p className='mb-4'>• Apa lagi, jom pindah rumah!</p>
                            <h5>Tips 2 : Tinggalkan barang kegunaan harian sahaja.</h5>
                            <p className='mb-1'>Jika korang akan berpindah dalam masa lagi beberapa hari, korang kemaskan semua barang masukkan dalam kotak. Tinggalkan keperluan penting sahaja yang akan digunakan untuk tempoh baki beberapa hari yang korang nak duduk kat rumah lama tu.</p>
                            <p className='mb-1'>Mulakan dengan mengemas barang yang jarang digunakan.</p>

                        </div>

                        <h6 className='mt-4'>Contoh :</h6>
                        <p className='mb-1'>Kemaskan semua, hanya tinggalkan beberapa helai baju
                            pakaian kerja, asingkan barang mekap yang penting sahaja. Asingkan
                            peralatan untuk cuci rumah (untuk kemaskan rumah sebelum tinggalkan
                            rumah tu)Balance tu Pek kesemuanya. Xyah pening last minute.</p>
                        <h5>Tips 3 : Susunatur pakaian dengan betul</h5>
                        <p className='mb-1'>Susun semua pakaian dalam kotak yang betul. Jangan campur adukkan
                            barang dalam kotak.</p>
                        <p className='mb-1'>Dengan ni, semua akan teratur dan senang nak cari kat Rumah baru.</p>
                        <h6>Contoh :</h6>
                        <p className='mb-1'>• Semua baju isteri dalam satu kotak, Baju suami dalam kotak
                            lain..</p>
                        <p className='mb-1'>• Cuba elakkan simpan barang yang tak berkaitan dalam satu kotak.</p>
                        <p className='mb-1'>• Labelkan kalau nak teratur.</p>
                        <h5>Tips 4: Asingkan dan labelkan kotak</h5>
                        <p className='mb-1'>Kotak yang digunakan hendaklah dilabelkan dengan nama barang yang berada dalam kotak tu dan lokasi barang tu hendak diletakkan di rumah baru.Letakkan label di luar kotak. The Movers ada menyediakan Kotak double wall untuk pemindahan rumah .</p>
                        <p className='mb-1'>Dengan ni, semua akan teratur dan senang nak cari kat Rumah baru.</p>
                        <h6>Contoh :</h6>
                        <p className='mb-1'>• Kotak A – Baju Office – Bilik 1</p>
                        <p className='mb-1'>• Kotak B – Gelas – Dapur</p>
                        <p className='mb-1'>• Kotak C – Kasut – Halaman</p>
                        <h5>Tips 5 : Letak barang yang digunakan untuk packing dalam satu bekas</h5>
                        <p className='mb-1'>Barang seperti Gunting, Tape, Tali, Marker akan cepat hilang/salah letak bila kelam kabut/messy masa tengah packing barang untuk pindah.</p>
                        <p className='mb-1'>So, pastikan semua barang tu diletakkan dalam satu bekas/bakul.</p>
                        <p className='mb-1'>Pastikan lepas guna, kembali diletak disitu.</p>
                        <p className='mb-1'>Barulah proses packing barang boleh berjalan lancar.</p>

                        <h5>Tips 6 : Pastikan ruang rumah baru tu siap sedia untuk terima barang</h5>
                        <p className='mb-1'>Sebelum pindah masuk, pastikan ruang rumah kita tu dah bersedia untuk terima loading barang.</p>
                        <p className='mb-1'>Agar takde halangan untuk barangan masuk dan tak halang laluan</p>
                        <p className='mb-1'>Make sure rumah dah bersih sebelum masuk barang… kalau boleh cuci rumah sehari sebelum atau minta cleaning services sehari dua sebelum nak PINDAH masuk . Pastikan Ada alas kaki tempat nak masuk Rumah tu agar x kotor masa nak PINDAH masuk .</p>
                        <h6>Contoh :</h6>
                        <p className='mb-1'>• Pastikan perabot besar tak berada ditengah2 ruang dan menghalang</p>
                        <p className='mb-1'>• urusan loading dan kerja mengemas.</p>
                        <p className='mb-1'>• Baru la mudah kotak2 nak masuk bilik tersebut.</p>

                        <h5>Tips 7 : Shrink wrapping & Bubble Wrapping barang</h5>
                        <p className='mb-1'>Jika mampu/rajin, boleh la wrapping barang korang agar tak kotor, rosak, berterabur masa pindah. Kalau taknak balut dengan wrapping plasik, boleh try guna surat khabar dan tape.
                            Antara barang yang selalu perlu wrap ialah sofa, bantal, tilam, barang2 kecik, barang2 mudah pecah dsb.</p>
                        <h5>Tips 8 : Barangan penting disimpan berasingan dan selamat</h5>
                        <p className='mb-1'>Barangan penting seperti dokumen, ubat-ubatan dan barangan kemas
                            baik simpan berasingan.</p>
                        <p className='mb-1'>Tak campur sekali dalam kotak biasa tu.</p>
                        <p className='mb-1'>Macam ubat tu, incase nak guna memang dah terus ada tanpa perlu nak geledah kotak besar.</p>
                        <h6>Contoh :</h6>
                        <p className='mb-1'>• Ubat-ubatan dan dokumen penting aku simpan dalam beg
                            galas personal</p>
                        <h5>Tips 9 : Buat checklist sebelum, semasa dan selepas packing barang/pindah</h5>
                        <p className='mb-1'>Checklist ni penting agar semua kerja memindah dan packing barang berjalan lancar.</p>
                        <p className='mb-1'>Takde la serabut sangat nanti. Senang nak trace apa yang penting. Contohnya : Checklist apa yang perlu ada, apa yang urgent, apa yang tertinggal.</p>
                        <p className='mb-1'>Checklist sebelum tinggalkan rumah lama tu sangat penting, agar takde barang tertinggal dan menyusahkan kelak.</p>
                        <p className='mb-1'>Buat tour satu rumah untuk kali terakhir sebelum tinggalkan rumah agar
                            takde barang tertinggal.</p>
                        <h5>Tips 10: Plan Susunatur perabot di rumah baru</h5>
                        <p className='mb-1'>Pastikan korang dah tahu mana nak susunatur perabot korang. Kalau bertuah, boleh suruh pekerja lori tolong susunkan sekali.</p>
                        <h5>Tips 11 : Clearkan dan cuci peti sejuk</h5>
                        <p className='mb-1'>Pastikan sehari sebelum pindah, korang clearkan barang dalam peti.</p>
                        <p className='mb-1'>Habiskan stok makanan, lap peti bersih-bersih.</p>
                        <p className='mb-1'>Nanti masuk rumah baru, dah boleh terus susunatur barang baru dalam
                            peti.</p>
                        <p className='mb-1'>Termasuk juga dapur (kalau korang pindahkan dapur memasak), dapur
                            kena lap dan balut dengan surat khabar cantek-cantek.</p>

                        <h5>Tips 12 : Sisihkan barangan yang tak berguna.</h5>
                        <p className='mb-1'>Buang mana-mana barang yang dah tak berguna…</p>
                        <p className='mb-1'>Jangan simpan dan penuhkan di rumah baru pulak…</p>
                        <p className='mb-1'>Baju lama boleh dijadikan kain buruk.</p>
                        <p className='mb-1'>Mana yang tak pakai, tak payah pikir untuk kenang kenangan. Sila buang.</p>
                        <p className='mb-1'>Menyemak je nanti.</p>
                        <h5>Tips 13 : Kemas Rumah/Settlekan bil sebelum tinggalkan rumah lama itu</h5>
                        <p className='mb-1'>Kalau Boleh kemas rumah sewa sebelum handover untuk penyewa baru.
                            Tolonglah ye, kalau korang sewa rumah walaupun masa dapat rumah tu,
                            rumah azab buruk.</p>
                        <p className='mb-1'>Nak ke semua Diatas dibuatkan oleh crew syarikat lori sewa sendiri ?
                            Hubungi The Movers Online dan kami menawarkan ke semua pakej AllIn-One untuk memudahkan proses pemindahan Anda . Kita orang
                            kaodim untuk korang!!</p>

                    </div>
                </div>
            </section>
        </>
    );
}

export default TipsPindha;
